import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { initMsalInstance } from './Config/msalConfig';

const msalInstance = initMsalInstance();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App msalInstance={msalInstance} />);

