import React, { createContext, useState, useContext } from 'react';
import {api} from '../api';

// Create AuthContext
const AuthContext = createContext();

// AuthProvider component
export const AuthProvider = ({ children }) => {
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    const checkUserStatus = async () => {
        // Replace this with your actual authentication logic
        try{
            const tokenValidResponse = await api.get('/auth/validate');
            const tokenValid = tokenValidResponse.data;
            if (tokenValid === true) {
                setIsUserLoggedIn(true);
            }
            else {
                setIsUserLoggedIn(false);
            }
            return tokenValid;
        }
        catch (error) {
            console.error('Error checking user status:', error);
            return false; // Handle error case
        }
    }

    const setUserLoggedIn = () => {
        setIsUserLoggedIn(true);
    }

    return (
        <AuthContext.Provider value={{ isUserLoggedIn, checkUserStatus, setUserLoggedIn }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use AuthContext
export const useAuth = () => {
    return useContext(AuthContext);
};