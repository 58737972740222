import {
    Navigate,
    Outlet,
  } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import {useIsAuthenticated} from "@azure/msal-react";

const ProtectedRoute = () => {
    const { isUserLoggedIn } = useAuth();
    const isAuthenticated = useIsAuthenticated();
    const location = useLocation();

    if (isUserLoggedIn && isAuthenticated) {
      return <Outlet />;
    }
    else if ( isAuthenticated && !isUserLoggedIn) {
      return <Navigate to="/" state={{message:location.pathname}} />;
    }
    else {
      return <Navigate to="/login" replace />;
    }
    
};

export default ProtectedRoute;