import React, { Component, Fragment } from 'react';
import { Collapse, Modal, ModalBody, ModalFooter } from 'reactstrap';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextareaAutosize from 'react-textarea-autosize';
import sanitizeHtml from 'sanitize-html';

import { api } from '../../api';
import Loading from '../../general/components/Loading';
import RichTextEditor from '../../general/text-editor/RichTextEditor';
import { BlueSwitch } from '../../general/components/BlueSwitch';

export default class WorkPermitOutputClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyId: null,
      sections: [],
      isLoading: true,
      isUrlValid: true,
      whitelistedUrls: [],
      blockedUrls: [],
      sectionToDeleteId: null,
      isRemoveSectionModalOpen: false,
      isRemoveOutputModalOpen: false,
      workPermits: [],
      recruiterOutputId: null,
      showGlobalOutput: true,
      clientName: '',
      clientDescription: '',
      clientDetailsChanged: false,
      manualAssessmentOutput: this.props.manualAssessmentDetails,
      manualAssessmentOutputChanged: false,
    };
  }

  componentDidMount() {
    this.setState({
      companyId: localStorage.getItem('companyId')
    }, () => {
      if (!this.props.selectedRegion || !this.props.selectedCountry) {
        window.location.replace('/#/');
      } else {
        this.fetchCustomClientDetails();
        this.fetchSections();
        this.fetchWhitelistedUrls();
      }
    }
    );
  }

  async fetchWhitelistedUrls() {
    await api.get('/whitelist').then((res) => this.setState({ whitelistedUrls: res.data }));
    // console.log(this.state.whitelistedUrls);
  }

  async fetchSections() {
    const recruiterOutputResponse = await api.get(`/work-permits/${this.props.selectedPermit.id}/recruiter-output/company/${this.state.companyId}`);
    let recruiterOutputCompanySettings = true;

    if(recruiterOutputResponse.data) {
      const recruiterOutputCompanySettingsResponse = await api.get(`/work-permits/${this.props.selectedPermit.id}/recruiter-output/${recruiterOutputResponse.data.id}/company/${this.state.companyId}`);

      if (!!recruiterOutputCompanySettingsResponse.data) {
        recruiterOutputCompanySettings = recruiterOutputCompanySettingsResponse.data.isGlobalSectionsActive;
      }
    }

    const sections = [];

    if (recruiterOutputResponse.data.sections) {
      recruiterOutputResponse.data.sections.forEach(section => {
        sections.push({
          id: section.id,
          staticTitle: section.title,
          title: section.title,
          content: section.content,
          isCollapseOpen: false,
          isSectionDirty: false,
        })
      });
    }

    this.setState({
      isLoading: false,
      sections,
      recruiterOutputId: recruiterOutputResponse.data.id,
      showGlobalOutput: recruiterOutputCompanySettings,
    });
  }

  async fetchCustomClientDetails() {
    //Get client details from database
    const clientDetailsResponse = await api.get(`/work-permits/${this.props.selectedPermit.id}/companies/${this.state.companyId}`);

    if (clientDetailsResponse && clientDetailsResponse.data) {
      let tempText = clientDetailsResponse.data.description;
      var regex = /<br\s*[/]?>/gi;
      // Regular expression:
      // <br matches the characters <br literally (case insensitive)
      // \s matches a whitespace character (includes tabs and line breaks).
      // * matches the previous token between zero and unlimited times, as many times as possible, giving back as needed (greedy)
      // ? matches the previous token between zero and one times, as many times as possible, giving back as needed (greedy)
      // g = global, match all instances of the pattern in a string, not just one
      // i = case-insensitive (so, for example, /a/i will match the string "a" or "A".

      tempText = tempText.replace(regex, '\n');
      // Replace all <br> tag to return character and show in the text area.

      const result = sanitizeHtml(tempText);
      clientDetailsResponse.data.description = result;

      this.setState({
        clientName: clientDetailsResponse.data.name,
        clientDescription: clientDetailsResponse.data.description
      })
    }
  }

  toggleCollapse = (index) => {
    const { sections } = this.state;

    sections[index].isCollapseOpen = !sections[index].isCollapseOpen;
    this.setState({ sections });
  }

  handleSectionTitle = (event, index) => {
    const { sections } = this.state;

    sections[index][event.target.name] = event.target.value;
    sections[index].isSectionDirty = true;
    this.setState({ sections });
  }

  handleRichTextFormatInput = (section, editorText) => {
    const { sections } = this.state;
    let sectionToEdit = sections.find(stateSection => stateSection.content === section.content);

    sectionToEdit.content = editorText;
    sectionToEdit.isSectionDirty = true;
    this.setState({ sections });
  }

  handleManualAssessmentOutputInput = (manualAssessmentOutput, editorText) => {
    manualAssessmentOutput.content = editorText;
    this.setState({
      manualAssessmentOutput,
      manualAssessmentOutputChanged: true
    })
  }

  addSection = () => {
    const { sections } = this.state;

    sections.push({
      staticTitle: 'Enter a new title below',
      title: '',
      content: '',
      isCollapseOpen: true,
      isSectionDirty: true,
    });

    this.setState({ sections });
  }

  onClickSave = () => {
    this.setState({ isLoading: true }, () => this.saveChanges())
  }

  onClickBack = () => {
    this.props.handleClose();
  }

  setClientName = (e) => {
    this.setState({
      clientName: e.target.value,
      clientDetailsChanged: true,
    })
  }

  setClientDescription = (e) => {
    this.setState({
      clientDescription: e.target.value,
      clientDetailsChanged: true,
    })
  }

  async saveChanges() {
    //  Save name and description logic
    let replaceLineBreakText = this.state.clientDescription;
    replaceLineBreakText = replaceLineBreakText.replace(/(?:\r\n|\r|\n)/g, '<br />');
    // Replace all return character to <br /> tag and store to database.

    //Non-capturing group (?:\r\n|\r|\n)
    // 1st Alternative \r\n
    // \r matches a carriage return (ASCII 13)
    // \n matches a line-feed (newline) character (ASCII 10)
    // 2nd Alternative \r
    // \r matches a carriage return (ASCII 13)
    // 3rd Alternative \n
    // \n matches a line-feed (newline) character (ASCII 10)

    const result = sanitizeHtml(replaceLineBreakText);
    this.setState({ clientDescription: result });

    if (this.state.clientDetailsChanged) {
      const customDetails = {
        name: this.state.clientName,
        description: this.state.clientDescription,
        company: this.state.companyId,
        workPermit: this.props.selectedPermit.id,
      }

      if (this.state.clientName === '' && this.state.clientDescription === '') {
        await api.delete(`/work-permits/${this.props.selectedPermit.id}/companies/${this.state.companyId}`);
      } else {
        await api.post(`/work-permits/companies/`, customDetails);
      }
    }

    if (this.state.manualAssessmentOutputChanged) {
      const manualAssessmentDetails = {
        id: this.state.manualAssessmentOutput.id,
        output: this.state.manualAssessmentOutput.content,
      };
      await api.patch(`companies/${this.state.companyId}/update-manually-assessed-permits`, manualAssessmentDetails);
    }

    for (const section of this.state.sections) {
      if (section.isSectionDirty) {
        const sectionToPost = {
          title: section.title,
          content: section.content,
          companyId: this.state.companyId,
        };

        if (section.id) {
          await api.patch(`/work-permits/${this.props.selectedPermit.id}/recruiter-output/${this.state.recruiterOutputId}/sections/${section.id}`, sectionToPost).then(() => {});
        } else {
          await api.post(`/work-permits/${this.props.selectedPermit.id}/recruiter-output/${this.state.recruiterOutputId}/sections`, sectionToPost).then(() => {});
        }
      }
    }

    this.props.handleClose();
  }

  handleRemoveSectionClick = (section, index) => {
    if (section.id) {
      this.setState({
        sectionToDeleteId: section.id,
        isRemoveSectionModalOpen: true,
      });
    } else {
      const { sections } = this.state;

      sections.splice(index, 1);
      this.setState({ sections });
    }
  }

  /**
   * Turn on the model to confirm deletion of the manual assessment output.
   */
  handleRemoveManualOutput = () => {
    this.setState({
      isRemoveOutputModalOpen: true,
    });
  }

  removeSection = () => {
    this.setState({ isLoading: true }, () => {
      api.delete(`/work-permits/${this.props.selectedPermit.id}/recruiter-output/${this.state.recruiterOutputId}/sections/${this.state.sectionToDeleteId}`)
        .then(() => {
          this.toggleModal();
          this.setState({sectionToDeleteId: null})
          this.fetchSections();
        });
    });
  }

  /**
   * Fully remove the output on the manual assessment required page.
   */
  removeManualOutput = () => {
    const manualAssessmentDetails = {
      id: this.state.manualAssessmentOutput.id,
      output: '',
    };

    const manualAssessmentState = this.state.manualAssessmentOutput;
    manualAssessmentState.content = '';

    this.setState({ isLoading: true }, () => {
      api.patch(`companies/${this.state.companyId}/update-manually-assessed-permits`, manualAssessmentDetails)
        .then(() => {
          this.toggleDeleteOutputModal();
          this.setState({
            isLoading: false,
            manualAssessmentOutput: manualAssessmentState,
          });
        });
    });
  }

  toggleModal = () => {
    this.setState(prevState => ({
      isRemoveSectionModalOpen: !prevState.isRemoveSectionModalOpen,
    }));
  }

  toggleDeleteOutputModal = () => {
    this.setState(prevState => ({
      isRemoveOutputModalOpen: !prevState.isRemoveOutputModalOpen,
    }));
  }

  onClickToggleGlobalOutput = async () => {
    await this.setState((prevState) => {
      return {showGlobalOutput: !prevState.showGlobalOutput };
    });

    const dataToPost = {
      recruiterOutput: this.state.recruiterOutputId,
      company: this.state.companyId,
      isGlobalSectionsActive: this.state.showGlobalOutput,
    };
    await api.post(`/work-permits/${this.props.selectedPermit.id}/recruiter-output/company/`, dataToPost);
  }

  isWorkPermitOutputValid() {
    if (this.state.sections.length > 0) {
      for (const section of this.state.sections) {
        if (section.isSectionDirty) {
          if ((!section.title || !section.content) ||
            (section.title.includes("<") || section.title.includes(">") ||
              section.content.includes("<") || section.content.includes(">"))) {
            return false;
          }
          
          if (section.content) {
            let parsedContent = JSON.parse(section.content);
            let hasSectionText = false;
            if (parsedContent.entityMap) {
              // console.log(parsedContent.entityMap);
              for (const [key] of Object.entries(parsedContent.entityMap)) {
                if (parsedContent.entityMap[key].data.url) {
                  if (!this.state.whitelistedUrls.includes(parsedContent.entityMap[key].data.url)) {
                    this.setIsUrlValid(false);
                    if (!this.state.blockedUrls.includes(parsedContent.entityMap[key].data.url)) {
                      const blacklistedUrls = this.state.blockedUrls;
                      blacklistedUrls.push(parsedContent.entityMap[key].data.url);
                      this.setState({ blockedUrls: blacklistedUrls });
                    }
                    return false;
                  }
                } else {
                  this.setIsUrlValid(true);
                }
              }
            }
            for (const block of parsedContent.blocks) {
              if (block.text) {
                hasSectionText = true;
                break;
              }
            }
            return hasSectionText;
          }
        }
      }
    }

    if(this.state.clientDetailsChanged || this.state.manualAssessmentOutputChanged) {
      return true;
    }

    return false;
  }

  setIsUrlValid(valid) {
    if (this.state.isUrlValid !== valid) {
      this.setState({ isUrlValid: valid });
    }
    // console.log(this.state.isUrlValid);
  }

  render() {
    const permit = this.props.selectedPermit;

    return (
      this.state.isLoading
        ?
        <Loading />
        :
        <React.Fragment>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="font-weight-bold">
                  <span className="text-secondary">Selected Permit: </span>
                  <span>{permit.name + (permit.deloittePermitId && " - "+ permit.deloittePermitId)}</span>
                </h2>
                <hr className="mt-4" />
                <h3 className="text-secondary font-weight-bold output-header mt-4">Custom client details</h3>
              </div>
              <div className="col-sm-6">
                <p className="output-paragraph">
                  Set a client specific name and description for the work permit. This will override any default name and/or description.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="wp-name">Name</label>
                  <input
                    id="wp-name"
                    name="wp-name"
                    className="form-control"
                    type="text"
                    defaultValue={this.state.clientName}
                    onChange={(event) => this.setClientName(event)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="wp-description">Description</label>
                  <TextareaAutosize
                    className="form-control text-area-sizing"
                    type="text"
                    name="wp-description"
                    id="wp-description"
                    value={this.state.clientDescription}
                    onChange={(event) => this.setClientDescription(event)}
                  />
                </div>
              </div>
            </div>

            {/* Configure the text to be shown on the 'Full Assessment Required' page. - Only show if the selected permit is toggled to be manually assessed */}
            {this.state.manualAssessmentOutput.status &&
              <Fragment>
                <div className="row">
                  <div className="col-sm-12">
                    <hr className="mt-4" />
                    <h3 className="text-secondary font-weight-bold output-header mt-4">Manual assessment output</h3>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-sm-6">
                    <p className="output-paragraph">
                      Configure any message which should appear on the 'Full Assessment Required' page, instead of the default text. Links may also be
                      imbeded to words with their full 'http://' address.
                    </p>
                  </div>
                </div>
                <RichTextEditor section={this.state.manualAssessmentOutput} handleRichTextFormatInput={this.handleManualAssessmentOutputInput} />
                <button className="btn btn-danger mt-4" onClick={() => this.handleRemoveManualOutput()}>Remove Output</button>
              </Fragment>
            }

            {/* Configure client specific recruiter output. */}
            <div className="row pt-4">
              <div className="col-sm-12">
                <hr className="mt-4" />
                <h3 className="text-secondary font-weight-bold output-header mt-4">Custom client output sections</h3>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-6">
                <p className="output-paragraph">
                  In addition to work permit specific output, we will also supply the recruiter with a client specific
                  information for each work permit. This information is optional and will be shown <b>in addition to </b>
                  the work permit output.
                </p>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <hr/>
                <FormControlLabel
                  control={
                    <BlueSwitch checked={this.state.showGlobalOutput} onChange={() => this.onClickToggleGlobalOutput()}/>
                  }
                  label="Show global work permit outputs"
                />
                <hr/>
              </div>
            </div>
            {
              this.props.selectedPermit && this.state.sections &&
              <React.Fragment>
                <div className="row">
                  {
                    this.state.sections.map((section, index) => {
                      return (
                        <div key={index} className="col-sm-12">
                          <hr className="mt-4" />
                          <div className="d-flex justify-content-between align-items-center" onClick={() => this.toggleCollapse(index)}>
                            <div className="output-section-header">{section.staticTitle}</div>
                            <div className="chevron"></div>
                          </div>
                          <Collapse className="mt-4 collapse-menu-item" isOpen={this.state.sections[index].isCollapseOpen}>
                            <label>Title</label>
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              maxLength={255}
                              value={this.state.sections[index].title}
                              onChange={(event) => this.handleSectionTitle(event, index)}
                              placeholder="Please enter a title for the section"
                            />
                            <RichTextEditor section={section} handleRichTextFormatInput={this.handleRichTextFormatInput} />
                            <button className="btn btn-danger mt-4" onClick={() => this.handleRemoveSectionClick(section, index)}>Remove Section</button>
                          </Collapse>
                        </div>
                      )
                    })
                  }
                </div>
                <div className="row mt-4">
                  <div className="col-sm-12">
                    <button type="button" className="btn btn-secondary" onClick={this.addSection}>Add Section</button>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-sm-12">
                    <button disabled={!this.isWorkPermitOutputValid()} type="button" className="btn btn-primary" onClick={this.onClickSave}>Save Changes</button>
                    <button type="button" className="btn btn-link" onClick={this.onClickBack}>Go Back</button>
                  </div>
                </div>
              </React.Fragment>
            }
          </div>
          <Modal isOpen={this.state.isRemoveSectionModalOpen} toggle={this.toggleModal} className={this.props.className}>
            <ModalBody>
              Are you sure you want to remove this section?
            </ModalBody>
            <ModalFooter>
              <button type="button" className="btn btn-secondary" onClick={this.toggleModal}>Close</button>
              <button type="button" className="btn btn-danger" onClick={this.removeSection}>Remove</button>
            </ModalFooter>
          </Modal>

          {/* Manual Assessment Output Modal */}
          <Modal isOpen={this.state.isRemoveOutputModalOpen} toggle={this.toggleDeleteOutputModal} className={this.props.className}>
            <ModalBody>
              Are you sure you want to remove the custom output?
            </ModalBody>
            <ModalFooter>
              <button type="button" className="btn btn-secondary" onClick={this.toggleDeleteOutputModal}>Close</button>
              <button type="button" className="btn btn-danger" onClick={this.removeManualOutput}>Remove</button>
            </ModalFooter>
          </Modal>
        </React.Fragment>
    )
  }
}
