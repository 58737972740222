import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../../api';
import { Collapse, Modal, ModalBody } from 'reactstrap';
import Header from '../../general/navigation/Header';
import Select from 'react-select';
import Loading from '../../general/components/Loading';

const EditCountry = (props) => {
  const{id:countryId}  = useParams();
  
  const [regions, setRegions] = useState([]);
  const [jobCodes, setJobCodes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countryName, setCountryName] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [isPointsBased, setIsPointsBased] = useState(false);
  const [countryToEdit, setCountryToEdit] = useState(null);
  const [collapseJobCodes, setCollapseJobCodes] = useState(false);
  const [collapseCountry, setCollapseCountry] = useState(true);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalText, setModalText] = useState('');
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [jobCodesDirty, setJobCodesDirty] = useState(false);

  // On mount: fetch countries and companies; if editing, set country & regions
  useEffect(() => {
    if (countryId) {
      setIsLoading(true);
      setCountryAndRegion();
    }
    api.get('/countries')
      .then(response => {
        if (response.data) {
          setCountries(response.data);
        }
      })
      .catch(error => console.error("Error fetching countries:", error));
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCompanies = async () => {
    try {
      const response = await api.get('/companies');
      const companiesList = [];
      const companiesWithDestination = [];
      if (response.data) {
        for (const company of response.data) {
          companiesList.push({ value: company.name, label: company.name, company });
          if (countryId) {
            const destinationFound = company.destinationCountries.find(country => country.id.toString() === countryId.toString());
            if (destinationFound) {
              companiesWithDestination.push({ value: company.name, label: company.name, company });
            }
          }
        }
      }
      setCompanies(companiesList);
      setSelectedCompanies(companiesWithDestination);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const setCountryAndRegion = async () => {
    try {
      const response = await api.get(`/countries/${countryId}`);
      const countryData = response.data;
      if (countryData) {
        setCountryToEdit(countryData);
        setCountryName(countryData.name);
        setCountryCode(countryData.code);
        setIsPointsBased(countryData.isPointsBased);

        // Map regions
        if (countryData.regions && countryData.regions.length) {
          const reg = countryData.regions.map(region => ({
            name: region.name,
            code: region.code,
            id: region.id
          }));
          setRegions(reg);
        }
        // Map job codes
        if (countryData.jobCodes && countryData.jobCodes.length) {
          const jobs = countryData.jobCodes.map(jobCode => ({
            name: jobCode.name,
            id: jobCode.id
          }));
          setJobCodes(jobs);
        }
      }
    } catch (error) {
      console.error("Error setting country and region:", error);
    }
  };

  // const addRegion = (event) => {
  //   event.preventDefault();
  //   setRegions(prev => [...prev, { name: '', code: '', id: null }]);
  // };

  const addJobCode = (event) => {
    event.preventDefault();
    setJobCodes(prev => [...prev, { name: '', id: null }]);
  };

  // const updateRegion = (updateType, index, event) => {
  //   const newRegions = [...regions];
  //   newRegions[index][updateType] = event.target.value;
  //   setRegions(newRegions);
  // };

  const updateJobCode = (updateType, index, event) => {
    const newJobCodes = [...jobCodes];
    newJobCodes[index][updateType] = event.target.value;
    setJobCodes(newJobCodes);
  };

  const removeJobCode = (index) => {
    const newJobCodes = [...jobCodes];
    newJobCodes.splice(index, 1);
    setJobCodes(newJobCodes);
    setJobCodesDirty(true);
  };

  // const removeRegion = (index) => {
  //   const newRegions = [...regions];
  //   newRegions.splice(index, 1);
  //   setRegions(newRegions);
  // };

  const updateCountryNameHandler = (event) => {
    setCountryName(event.target.value);
  };

  const updateCountryCodeHandler = (event) => {
    setCountryCode(event.target.value);
  };

  // const togglePointsBased = () => {
  //   setIsPointsBased(prev => !prev);
  // };

  const submitCountry = (event) => {
    event.preventDefault();
    const duplicateCountryAlert = "Country already exists!";
    const duplicateCountries = countries.filter(country => country.name === countryName);
    if (duplicateCountries.length >= 1 && !countryToEdit) {
      alert(duplicateCountryAlert);
    } else {
      if (countryToEdit) {
        // Check if editing causes duplicate names
        const duplicate = duplicateCountries.find(duplicateCountry => duplicateCountry.id === countryToEdit.id);
        if (duplicate || duplicateCountries.length === 0) {
          setIsLoading(true);
          updateCountry();
        } else {
          alert(duplicateCountryAlert);
        }
      } else {
        setIsLoading(true);
        const countryObject = {
          name: countryName,
          code: countryCode,
          isPointsBased
        };
        api.post('/countries', countryObject)
          .then(response => createRegions(response.data))
          .catch(error => {
            console.error("Error creating country:", error);
          });
      }
    }
  };

  const submitJobCodes = () => {
    if (countryToEdit) {
      setIsLoading(true);
      updateJobCodes();
    }
  };

  const updateCountry = async () => {
    const countryObject = {
      name: countryName,
      code: countryCode,
      isPointsBased
    };
    await api.patch(`/countries/${countryToEdit.id}`, countryObject);
    updateRegions();
  };

  const updateRegions = async () => {
    await api.patch(`/countries/${countryToEdit.id}/regions`, regions);
    await updateCompaniesWithCountryDestination(countryToEdit.id);
    setIsLoading(false);
    setModalText('Country updated');
    toggleModal();
  };

  const updateCompaniesWithCountryDestination = async (countryId) => {
    const companiesForUpdate = selectedCompanies.map(companyObj => companyObj.company);
    await api.patch(`/countries/${countryId}/update-company-destinations`, { companies: companiesForUpdate });
  };

  const updateJobCodes = async () => {
    await api.patch(`/countries/${countryToEdit.id}/job-codes`, jobCodes);
    setIsLoading(false);
    setJobCodesDirty(false);
    setModalText("Job codes updated");
    toggleModal();
  };

  const createRegions = async (country) => {
    if (regions.length > 1) {
      for (const region of regions) {
        await api.post(`/countries/${country.id}/regions`, { name: region.name, code: region.code })
          .catch(error => console.error("Error creating region:", error));
      }
    } else {
      await api.post(`/countries/${country.id}/regions`, { name: country.name, code: country.code })
        .catch(error => console.error("Error creating region:", error));
    }
    await updateCompaniesWithCountryDestination(country.id);
    setCountryToEdit(country);
    setIsLoading(false);
    setModalText('Country has been created');
    toggleModal();
  };

  const toggleJobCodes = () => {
    setCollapseJobCodes(prev => !prev);
  };

  const toggleCountryDetails = () => {
    setCollapseCountry(prev => !prev);
  };

  const onClickCancel = () => {
    window.location.replace('/#/countries');
  };

  const onClickCancelJobCodes = () => {
    toggleJobCodes();
    setCountryAndRegion();
  };

  const checkRegionsFilled = () => {
    let regionsFilledCorrectly = true;
    for (const region of regions) {
      const duplicateRegion = regions.filter(countryRegion => countryRegion.name === region.name);
      if (duplicateRegion.length > 1) {
        regionsFilledCorrectly = false;
      }
      if (!region.name || !region.code) {
        regionsFilledCorrectly = false;
      }
    }
    return regionsFilledCorrectly;
  };

  const checkJobCodesFilledAndSubmit = (event) => {
    event.preventDefault();
    let jobCodesFilledCorrectly = true;
    let alertMessage = null;
    for (const jobCode of jobCodes) {
      const duplicateJobCode = jobCodes.filter(code => code.name === jobCode.name);
      if (duplicateJobCode.length > 1) {
        jobCodesFilledCorrectly = false;
        alertMessage = 'Job Code already exists. Please enter a unique job code.';
      }
      if (!jobCode.name) {
        jobCodesFilledCorrectly = false;
        alertMessage = 'Please ensure a name is entered for all job codes before submission.';
      }
    }
    if (!(jobCodes.length > 0) && !jobCodesDirty) {
      jobCodesFilledCorrectly = false;
      alertMessage = 'Please enter at least 1 job code before submission.';
    }
    if (jobCodesFilledCorrectly) {
      submitJobCodes();
    } else {
      alert(alertMessage);
    }
  };

  const toggleModal = () => {
    setModal(prev => !prev);
  };

  const toggleDeleteModal = (open) => {
    setDeleteModal(open);
  };

  const deleteCountry = () => {
    api.patch(`/countries/${countryToEdit.id}`, { isDeleted: true })
      .then(() => window.location.replace('/#/countries'));
  };

  const closeModal = () => {
    toggleModal();
    if (countryToEdit) {
      setIsLoading(true);
      setCountryAndRegion();
      getCompanies();
      if (!countryId) {
        redirectToCountryEdit();
      }
    }
  };

  const redirectToCountryEdit = () => {
    window.location.replace(`/#/countries/${countryToEdit.id}/edit`);
  };

  const selectCompany = (event) => {
    if (event) {
      setSelectedCompanies(event);
    } else {
      setSelectedCompanies([]);
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <Header 
            showClientNavigation={false} 
            showImmigrationNavigation={false} 
            displayName={null} 
            link={null}
          />
          <div className="pb-5">
            <div className="container manage-countries-container">
              <h3 className="text-secondary font-weight-bold mt-5">
                {countryToEdit ? "Edit country" : "Create country"}
              </h3>
              <div className="d-flex justify-content-center">
                <form className="w-50 pt-5">
                  <div className="d-flex justify-content-between collapse-toggle" onClick={toggleCountryDetails}>
                    <h4 className="font-weight-bold text-secondary mt-5 mb-2">Country Details</h4>
                    <h4 className="font-weight-bold text-secondary mt-5">{collapseCountry ? "-" : "+"}</h4>
                  </div>
                  {companies.length > 0 && (
                    <Collapse isOpen={collapseCountry}>
                      <div className="form-group w-100">
                        <label className="text-secondary" htmlFor="countryName">Country Name</label>
                        <input onChange={updateCountryNameHandler} value={countryName} type="text" className="form-control" id="countryName"/>
                      </div>
                      <div className="form-group w-100">
                        <label className="text-secondary" htmlFor="countryCode">Country Code</label>
                        <input onChange={updateCountryCodeHandler} value={countryCode} type="text" className="form-control" id="countryCode"/>
                      </div>
                      {/* Uncomment below if points-based toggle is needed */}
                      {/*
                      <div className="form-group w-100">
                        <div className="form-check">
                          <input 
                            checked={isPointsBased} 
                            onChange={togglePointsBased} 
                            className="form-check-input" 
                            type="checkbox" 
                            id="pointsCheck" 
                          />
                          <label className="form-check-label text-secondary" htmlFor="pointsCheck">
                            Points-based?
                          </label>
                        </div>
                      </div>
                      */}
                      <label className="form-check-label text-secondary mt-4 mb-2">
                        Select companies for which this is a destination country:
                      </label>
                      <Select 
                        className="mb-5" 
                        isMulti={true} 
                        options={companies} 
                        onChange={selectCompany} 
                        defaultValue={selectedCompanies}
                      />
                      <div className="d-flex justify-content-center">
                        <button 
                          disabled={!checkRegionsFilled() || !countryName || !countryCode || (regions.length === 1)} 
                          type="submit" 
                          onClick={submitCountry} 
                          className="mt-2 w-25 btn btn-secondary"
                        >
                          Submit
                        </button>
                        <button onClick={onClickCancel} className="mt-2 ml-3 w-25 btn btn-danger">Cancel</button>
                      </div>
                    </Collapse>
                  )}
                  <hr />
                  <div className="d-flex justify-content-between collapse-toggle" onClick={toggleJobCodes}>
                    <h4 className="font-weight-bold text-secondary mt-5 mb-2">Job Codes</h4>
                    <h4 className="font-weight-bold text-secondary mt-5">{collapseJobCodes ? "-" : "+"}</h4>
                  </div>
                  <Collapse isOpen={collapseJobCodes}>
                    {jobCodes.map((jobCode, index) => (
                      <div className="d-flex" key={index}>
                        <div className="form-group w-50 mr-1">
                          <label className="text-secondary">Job Code Name</label>
                          <input 
                            value={jobCodes[index].name} 
                            type="text" 
                            onChange={(event) => updateJobCode("name", index, event)} 
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <button 
                            type="button" 
                            onClick={() => removeJobCode(index)} 
                            className="btn btn-danger h-50 ml-1 remove-region-button"
                          >
                            X
                          </button>
                        </div>
                      </div>
                    ))}
                    {!countryToEdit ? (
                      <div className="d-flex justify-content-center mt-3 mb-3 text-danger">
                        <span>Please submit country before adding job codes.</span>
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className="d-flex justify-content-center mt-3 mb-3 text-danger">
                          <button onClick={addJobCode} className="w-25 btn btn-primary m-2">Add Job Code</button>
                        </div>
                        <div className="d-flex justify-content-center">
                          <button 
                            type="submit" 
                            onClick={checkJobCodesFilledAndSubmit} 
                            className="mt-2 w-25 btn btn-secondary"
                          >
                            Submit
                          </button>
                          <button onClick={onClickCancelJobCodes} className="mt-2 ml-3 w-25 btn btn-danger">
                            Cancel
                          </button>
                        </div>
                      </React.Fragment>
                    )}
                  </Collapse>
                  <hr />
                </form>
              </div>
              {countryToEdit && (
                <div className="d-flex justify-content-center">
                  <button 
                    onClick={() => toggleDeleteModal(true)} 
                    className="mt-2 ml-3 btn btn-danger"
                  >
                    Delete Country
                  </button>
                </div>
              )}
            </div>
          </div>
          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalBody>
              <div className="d-flex justify-content-center">
                {modalText}
              </div>
              <div className="d-flex justify-content-center mt-5">
                <button type="button" className="btn btn-primary" onClick={closeModal}>Close</button>
              </div>
            </ModalBody>
          </Modal>
          <Modal isOpen={deleteModal} toggle={() => toggleDeleteModal(false)}>
            <ModalBody>
              <div className="d-flex justify-content-center">
                Are you sure you want to delete this country?
              </div>
              <div className="d-flex justify-content-center mt-5">
                <button type="button" className="btn btn-danger mr-2" onClick={deleteCountry}>Delete</button>
                <button type="button" className="btn btn-primary" onClick={() => toggleDeleteModal(false)}>Cancel</button>
              </div>
            </ModalBody>
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default EditCountry;