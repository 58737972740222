import React, { Fragment, useContext, useEffect, useState } from 'react';
import Header from '../../general/navigation/Header';
import Loading from '../../general/components/Loading';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { fetchCompanyAnnouncements, deleteAnnouncement, fetchCompany } from '../../services/CompanyService';
import { CompanyContext } from '../../context/CompanyContext';
import { AddCircleOutlineOutlined, HighlightOffOutlined } from '@mui/icons-material';
import { Link, useParams } from 'react-router';
import {convertFromRaw} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import jsonChecker from '../../general/components/JsonChecker';

const ManageAnnouncements = () => {
	const [companyState, companyDispatch] = useContext(CompanyContext);
	const { id: companyId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [announcementToDelete, setAnnouncementToDelete] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

	// Fetch the announcements.
	useEffect(() => {
        fetchStateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Fetch and set all the state data.
	 */
	const fetchStateData = async () => {
		if (!companyState.selectedCompany) {
			const selectedCompany = await fetchCompany(companyId);

			companyDispatch({
				type: 'SELECT_COMPANY',
				selectedCompany: selectedCompany,
			});
		}

		await initialiseAnnouncements();

		setIsLoading(false);
	};

	const initialiseAnnouncements = async () => {
		const announcementsResponse = await fetchCompanyAnnouncements(companyId);
        /**
         * if announcement text is in JSON format it means it's a Rich text editor editted text.
         * Conver to Html for better visibility.
         */
        for (var key in announcementsResponse) {
            if(jsonChecker(announcementsResponse[key].text)){
                let resultAnnouncements = JSON.parse(announcementsResponse[key].text);
                //Convert JSON to state, then state to html
                const contentState = convertFromRaw(JSON.parse(JSON.stringify(resultAnnouncements)));
                let html = stateToHTML(contentState);
                //adding a html field if html exist.
                announcementsResponse[key].html = html;
            }
        }
        companyDispatch({
            type: 'SET_ANNOUNCEMENTS',
            companyAnnouncements: announcementsResponse,
        });
    };

    const handleDelete = async () => {
        setIsLoading(true);
        await deleteAnnouncement(announcementToDelete);
        await initialiseAnnouncements();
        toggleDecommissionModal();
        setIsLoading(false);
    };

    const toggleDecommissionModal = () => {
        setIsModalOpen(!isModalOpen);
    };

	return (
		isLoading
		? <Loading />
		: <Fragment>
            <Header
                showClientNavigation ={true}
                showImmigrationNavigation={false}
                displayName={companyState.selectedCompany.name}
                link={"/select-company"}
            />
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 d-flex justify-content-between">
                    <h3 className="mr-5 gw__section-headings">Announcements</h3>
                        <Link to={`/companies/${companyId}/announcements/create`}>
                            <button type="button" className="btn btn-outline-green">
                                <AddCircleOutlineOutlined className="gw__add-icon"/>
                                NEW ANNOUNCEMENT
                            </button>
                        </Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 mb-b">
                        { companyState.companyAnnouncements &&
                            <table className="table table-hover mt-5 mb-5">
                                <thead>
                                    <tr>
                                        <th scope="col">TITLE</th>
                                        <th scope="col">ANNOUNCEMENT</th>
                                        <th></th>{/* Edit */}
                                        <th></th>{/* Delete */}
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        companyState.companyAnnouncements.map((announcement, index) => {
                                            /**
                                             * Condintionally render html or plain text
                                             * If html exist, render html, otherwise render plain text.
                                            */
                                            let announcementText
                                            if(announcement.html){
                                                announcementText = <td dangerouslySetInnerHTML={{ __html:announcement.html.concat(' ') }} />
                                            }else{
                                                announcementText = <td className="gw__table-items">{announcement.text}</td>
                                            }
                                            return(
                                                <tr key={index}>
                                                    <td className="gw__table-items">{announcement.title}</td>
                                                    {/** Announcement text rendering below */}
                                                   {announcementText}
                                                    <td>
                                                        <Link className="gw__nav_links gw__edit-permit-button" to={`/companies/${companyId}/announcements/${announcement.id}/edit`}>
                                                            <svg width="2em" height="2em" viewBox="0 0 18 18" className="bi bi-pencil" fillRule="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" d="M11.293 1.293a1 1 0 0 1 1.414 0l2 2a1 1 0 0 1 0 1.414l-9 9a1 1 0 0 1-.39.242l-3 1a1 1 0 0 1-1.266-1.265l1-3a1 1 0 0 1 .242-.391l9-9zM12 2l2 2-9 9-3 1 1-3 9-9z"/>
                                                                <path fillRule="evenodd" d="M12.146 6.354l-2.5-2.5.708-.708 2.5 2.5-.707.708zM3 10v.5a.5.5 0 0 0 .5.5H4v.5a.5.5 0 0 0 .5.5H5v.5a.5.5 0 0 0 .5.5H6v-1.5a.5.5 0 0 0-.5-.5H5v-.5a.5.5 0 0 0-.5-.5H3z"/>
                                                            </svg>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <HighlightOffOutlined className="gw__delete-permit-icon" onClick={() => {setAnnouncementToDelete(announcement.id); toggleDecommissionModal();}}/>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div>

            {/* Decommission Modal */}
            <Modal isOpen={isModalOpen} toggle={toggleDecommissionModal}>
                <ModalHeader>
                    Delete Announcement
                </ModalHeader>
                <ModalBody>
                    <b>Are you sure you want to delete this announcement?</b><br />
                    This announcement and its selected countries will be completely removed for the selected client.
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-cancel" onClick={toggleDecommissionModal}>No, Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={handleDelete}>Yes, Remove</button>
                </ModalFooter>
            </Modal>
		</Fragment>
	);
};

export default ManageAnnouncements;

